<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="800"
    v-if="currentUser && currentUser.selectedProfile.profilableType == 'Store'" 
    >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        v-if="(!shipment.ticketUrl || !shipment.tracking) && order.storeId == currentUser.store.id"
        outlined
        v-bind="attrs"
        v-on="on"
        >
        <v-card-text
          class="pa-2 text-center caption secondary--text font-weight-medium"
          >
          ACTUALIZAR ENVÍO
        </v-card-text>
      </v-card>
    </template>
    
    <v-card>
      <v-card-title
        class="justify-center text-subtitle-1"
        >
        Actualizar datos de envío
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <v-row>
            <v-col
              cols="12"
              md="6"
              >
              <v-file-input
                truncate-length="15"
                label="Comprobante de envío"
                v-model="attributes.ticket"
                filled
                hide-details="auto"
                accept="image/png, image/jpeg, image/bmp"
                :rules="[
                v => !!v || 'Campo obligatorio'
                ]"
                ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              md="6"
              >
              <v-text-field
                label="Número de seguimiento"
                v-model="attributes.tracking"
                filled
                hide-details="auto"
                :rules="[
                v => !!v || 'Campo obligatorio'
                ]"
                ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions
        class="px-6"
        >
        <v-btn
          block
          color="primary"
          @click="edit"
          >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Edit } from '@/graphql/mutations/shipments'

export default {
  data: () => ({
    dialog: false,
    valid: true,
    attributes: {
      ticket: null,
      tracking: null
    }
  }),

  props: {
    shipment: {
      required: true,
      type: Object
    },

    order: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    edit () {
      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: Edit,
          variables: {
            input: {
              attributes: this.attributes,
              shipmentId: this.shipment.id
            }
          }
        }).then ( res => {
          this.$router.go()
        })
      }
    }
  }
}
</script>
